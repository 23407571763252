import { mapState } from 'pinia';
import { useMetaStore } from '@@/stores/Meta';

export const defaultText = 'Create Free Account';

export default {
  computed: {
    ...mapState(useMetaStore, { defaultTrial: (state) => state.pricing.default_trial }),

    defaultTrialTitle() {
      return this.defaultTrial?.trial_title || defaultText;
    },

    registerButtonText() {
      return this.defaultTrial?.trial_button_text || defaultText;
    },
  },
};
