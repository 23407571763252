<template>
  <Card
    :has-body-padding="true"
  >
    <template #body>
      <div class="tw-flex tw-flex-col lg:tw-flex-row">
        <Button
          v-for="button in buttons"
          :key="button.text"
          :additional-class-names="button.class"
          :href="button.href"
          :to="button.to"
          :type="button.type"
          @click="button.click"
          @mousedown="button.mousedown"
        >
          {{ button.text }}
        </Button>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { addReturnTo } from '@@/utils/LoginUtils';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { loginViews, useLoginStore } from '@@/stores/Login';
import LoginViewMixin from '@@/components/Login/LoginViewMixin';
import DefaultTrialMixin from '@@/utils/DefaultTrialMixin';

export default {
  name: 'LoginActions',

  mixins: [
    LoginViewMixin,
    DefaultTrialMixin,
  ],

  computed: {
    ...mapState(useLoginStore, { loginView: (state) => state.loginView }),

    buttons() {
      const buttons = [];

      /* eslint no-script-url: off */
      const defaultButton = () => ({
        class: 'tw-flex-grow tw-mb-4 lg:tw-mb-0 lg:tw-mr-2',
        click: () => {},
        href: 'javascript:void(0);',
        mousedown: () => {},
      });

      const registerButton = {
        ...defaultButton(),
        href: null,
        text: this.registerButtonText,
        to: '/user/register?source=signup',
        type: ButtonTypes.secondary,
      };

      const loginButton = {
        ...defaultButton(),
        class: 'tw-flex-grow lg:tw-ml-2',
        href: null,
        text: 'Log In',
        to: '/user/login',
        type: ButtonTypes.secondary,
      };

      const loginInsteadButton = {
        ...defaultButton(),
        href: null,
        text: 'Already have an account? Log In',
        to: '/user/login',
        type: ButtonTypes.secondary,
      };

      const loginWithEmailButton = {
        ...defaultButton(),
        click: () => this.sendEmail(),
        mousedown: (e) => e.originalEvent.preventDefault(),
        text: 'Login with Email',
        type: ButtonTypes.secondary,
      };

      const forgotPasswordButton = {
        ...defaultButton(),
        class: 'tw-flex-grow lg:tw-ml-2',
        href: null,
        // SEE: https://www.quora.com/Is-I-forget-or-I-forgot-grammatically-correct-when-you-are-referring-to-the-present
        text: 'Forgot your password?',
        to: '/user/forgotpassword',
        type: ButtonTypes.secondary,
      };

      const resendEmailButton = {
        ...defaultButton(),
        click: () => this.sendEmail(true),
        text: 'Resend Email',
        type: ButtonTypes.secondary,
      };

      const startOverButton = {
        ...defaultButton(),
        class: 'tw-flex-grow lg:tw-ml-2',
        click: () => this.startOver(),
        mousedown: (e) => e.originalEvent.preventDefault(),
        text: 'Start Over',
        type: ButtonTypes.secondary,
      };

      if (this.isLoginEnterEmailView) {
        buttons.push(registerButton, forgotPasswordButton);
      }
      else if (this.isLoginEnterPasswordView) {
        buttons.push(loginWithEmailButton, forgotPasswordButton);
      }
      else if (this.isForgotPasswordEnterEmailView) {
        buttons.push(registerButton, loginButton);
      }
      else if (this.isForgotPasswordEnterPasswordView) {
        buttons.push(registerButton, startOverButton);
      }
      else if (this.isForgotPasswordLinkSentView
        || this.isLoginLinkSentView
        || this.isRegisterLinkSentView) {
        buttons.push(resendEmailButton, startOverButton);
      }
      else if (this.isRegisterEnterEmailView) {
        buttons.push(loginInsteadButton);
      }
      else if (this.isChangeEmailLinkSentView || this.isDeleteAccountLinkSentView) {
        buttons.push(resendEmailButton);
      }

      return buttons.map((button) => ({
        ...button,
        href: button.href && button.href !== 'javascript:void(0);'
          ? addReturnTo(button.href, this.$route.query)
          : button.href,
        to: button.to
          ? addReturnTo(button.to, this.$route.query)
          : button.to,
      }));
    },
  },

  methods: {
    ...mapActions(useLoginStore, [
      'makeLoginLinkRequest',
      'makePasswordLinkRequest',
      'makeRegisterLinkRequest',
      'makeSettingsDeleteLinkRequest',
      'makeSettingsEmailLinkRequest',
      'setCredentials',
      'setLoginView',
    ]),

    async sendEmail(resend = false) {
      const handleError = () => this.$toast.open({
        message: `Error! Unable to ${resend ? 'resend' : 'send'} link.`,
        type: 'error',
      });

      try {
        let linkSent;

        this.$loading.start();

        if (this.isForgotPasswordLinkSentView) {
          linkSent = await this.makePasswordLinkRequest();
        }
        else if (this.isRegisterLinkSentView) {
          linkSent = await this.makeRegisterLinkRequest();
        }
        else if (this.isChangeEmailLinkSentView) {
          linkSent = await this.makeSettingsEmailLinkRequest();
        }
        else if (this.isDeleteAccountLinkSentView) {
          linkSent = await this.makeSettingsDeleteLinkRequest();
        }
        else {
          const { return_to } = this.$route.query;
          const payload = { return_to };

          linkSent = await this.makeLoginLinkRequest(payload);
        }

        if (!linkSent) {
          handleError();
        }
        else if (resend) {
          this.$toast.open({
            message: 'Success! Please check your email for your new link.',
            type: 'success',
          });
        }
        else {
          this.setLoginView(loginViews.loginLinkSent);
        }
      }
      catch (e) {
        handleError();
      }

      this.$loading.finish();
    },

    startOver() {
      if (this.isForgotPasswordEnterPasswordView) {
        this.$router.push('/user/forgotpassword');
        return;
      }

      if (this.isForgotPasswordLinkSentView) {
        this.setLoginView(loginViews.forgotPasswordEnterEmail);
      }
      else if (this.isRegisterLinkSentView) {
        this.setLoginView(loginViews.registerEnterEmail);
      }
      else if (this.isDeleteAccountLinkSentView) {
        this.setLoginView(loginViews.deleteAccount);
      }
      else {
        this.setLoginView(loginViews.loginEnterEmail);
      }

      this.setCredentials({ email: null, passcode_validate: null, password: null });
    },
  },
};
</script>
