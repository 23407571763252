import { mapState } from 'pinia';
import { loginViews, useLoginStore } from '@@/stores/Login';

export default {
  computed: {
    ...mapState(useLoginStore, { loginView: (state) => state.loginView }),

    isChangeEmailEnterEmailView() {
      return this.loginView === loginViews.changeEmailEnterEmail;
    },

    isChangeEmailLinkSentView() {
      return this.loginView === loginViews.changeEmailLinkSent;
    },

    isDeleteAccountView() {
      return this.loginView === loginViews.deleteAccount;
    },

    isDeleteAccountLinkSentView() {
      return this.loginView === loginViews.deleteAccountLinkSent;
    },

    isForgotPasswordEnterEmailView() {
      return this.loginView === loginViews.forgotPasswordEnterEmail;
    },

    isForgotPasswordLinkSentView() {
      return this.loginView === loginViews.forgotPasswordLinkSent;
    },

    isForgotPasswordEnterPasswordView() {
      return this.loginView === loginViews.forgotPasswordEnterPassword;
    },

    isLoginEnterEmailView() {
      return this.loginView === loginViews.loginEnterEmail;
    },

    isLoginEnterPasswordView() {
      return this.loginView === loginViews.loginEnterPassword;
    },

    isLoginLinkSentView() {
      return this.loginView === loginViews.loginLinkSent;
    },

    isRegisterEnterEmailView() {
      return this.loginView === loginViews.registerEnterEmail;
    },

    isRegisterLinkSentView() {
      return this.loginView === loginViews.registerLinkSent;
    },

    isRegisterLinkVerifiedView() {
      return this.loginView === loginViews.registerLinkVerified;
    },
  },
};
